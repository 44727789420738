import PropTypes from 'prop-types';

export const circularImageType = {
  imgSrc: PropTypes.string,
  caption: PropTypes.string,
  href: PropTypes.string,
  borderColor: PropTypes.string,
  sideIcon: PropTypes.string,
  mainIcon: PropTypes.string,
};

export const tileType = PropTypes.shape({
  content: PropTypes.object,
  labels: PropTypes.object,
  favoriteButton: PropTypes.shape({
    favoriteButtonProps: PropTypes.shape({
      buttonProps: PropTypes.shape({
        userLoggedIn: PropTypes.bool,
      }),
    }),
  }) || undefined,
  trailmixButton: PropTypes.shape({
    name: PropTypes.string,
    labels: PropTypes.shape({
      addToTrailmix: PropTypes.string,
    }),
  }) || undefined,
  tagsButton: PropTypes.shape({
    items: PropTypes.array,
    title: PropTypes.string,
  }) || undefined,
});
