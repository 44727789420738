import React from 'react';
import FavoriteButton from '../../FavoriteButton';
import MoreTagsDropdown from '../../tile/MoreTagsDropdown';
import UnauthenticatedButton from '../../auth/buttons/UnauthenticatedButton';

const renderButtons = (tile) => {
  if (tile.content.type === 'trailmix') {
    return [];
  }

  const buttons = [
    <FavoriteButton {...tile.favoriteButton} />,
    <UnauthenticatedButton {...tile.trailmixButton} />
  ];

  if (tile.tagsButton.items) {
    buttons.push(<MoreTagsDropdown dropdownId={tile.content.apiName} {...tile.tagsButton} />);
  }

  return buttons;
};

export default renderButtons;
