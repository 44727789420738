import React from 'react';
import { circularImageType } from './shared/sharedPropTypes';

const CircularImage = ({
  imgSrc,
  caption,
  sideIcon,
  mainIcon,
  mainIconStyles,
  href,
  isSelected,
  noHover,
  roleColor,
  borderColor = 'white'
}) => {
  const renderSideIcon = () => {
    if (!sideIcon) {
      return null;
    }

    return (
      <div className={`th-circular-image__side-icon ${roleColor}__side-icon`}>
        <img src={sideIcon} alt="" />
      </div>
    );
  };

  const renderImageOrIcon = () => {
    if (imgSrc) {
      return (
        <img src={imgSrc} alt="" className="th-circular-image__image" style={{ borderColor }} />
      );
    }
    return (
      <div className={`th-circular-image__main-icon ${roleColor}__icon`}>
        <img src={mainIcon} alt="" style={mainIconStyles} />
      </div>
    );
  };

  const renderDiv = () => (
    <div>
      <figure>{renderImageOrIcon()}</figure>
      {renderSideIcon()}
      <figcaption className="tds-text_bold slds-p-horizontal_x-large tds-text-size_4 slds-m-top_x-small" style={{lineHeight: '1.4rem'}}>
        {caption}
      </figcaption>
    </div>
  );

  const renderAnchor = () => (
    <a href={href} className="th-circular-image__anchor">
      {renderDiv()}
    </a>
  );

  const selectedCss = isSelected ? 'selected' : '';
  const hoverCss = noHover ? 'no-hover' : `${roleColor || 'default-brand'}__hover`;
  return (
    <div
      className={`slds-text-align_center slds-p-top_small th-circular-image ${selectedCss} ${hoverCss}`}
    >
      {href ? renderAnchor() : renderDiv()}
    </div>
  );
};

CircularImage.propTypes = circularImageType;

export default CircularImage;
