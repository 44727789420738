import React from 'react';
import PropTypes from 'prop-types';

const CareerPathCard = ({
  title,
  description,
  skills,
  stats,
  preTitle,
  showBottomNotch = true,
  roleColor = 'default-brand'
}) => {
  const renderSkills = () => (
    <div className="slds-grid slds-wrap slds-m-top_medium">
      {skills.map((skill) => (
        <div key={skill.caption} className="slds-size_1-of-1 slds-medium-size_1-of-3 th-career-tab-panel__content">
          <img src={skill.imgSrc} alt="" className="th-career-path-card__skill-image" />
          <div className="slds-text-heading_small tds-text-size_4 slds-p-horizontal_x-large">
            {skill.caption}
          </div>
        </div>
      ))}
    </div>
  );

  const renderStats = () => (
    <div
      className={`tds-color_white slds-m-top_large slds-p-vertical_large slds-wrap slds-grid ${roleColor}__bg`}
      style={{ borderRadius: '0 0 15px 15px' }}
    >
      {stats.map((stat) => (
        <div key={stat.caption} className="slds-size_1-of-1 slds-medium-size_1-of-3 th-career-tab-panel__content">
          <div className="tds-text-heading_neutraface-x-large slds-m-top_small">
            {stat.imgSrc ? (
              <img src={stat.imgSrc} alt="" className="slds-m-horizontal_xx-small" />
            ) : null}
            {stat.number}
          </div>
          <div className="slds-show_medium slds-m-horizontal_medium tds-text-size_4 tds-text_bold">
            {stat.caption}
          </div>
          <div className="slds-show_medium slds-m-horizontal_medium tds-text-size_4">{stat.subCaption}</div>

          <div className="slds-hide_medium slds-m-horizontal_medium tds-text-size_4">
            <span className="tds-text_bold">{stat.caption}</span> {stat.subCaption}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <div
        className="slds-container_center tds-bg_white th-career-path-card"
        style={{ borderRadius: '15px', maxWidth: '580px' }}
      >
        <div className="slds-text-align_center slds-p-top_xx-large">
          {preTitle && (
            <div
              style={{ letterSpacing: '2px' }}
              className="tds-text-size_6 slds-m-bottom_xx-small slds-m-horizontal_x-small tds-text-size_5 tds-text_uppercase tds-text_bold"
            >
              {preTitle}
            </div>
          )}
          <h2 className="tds-text-heading_neutraface-large th-career-tab-panel__content">
            {title}
          </h2>
          <div
            className="slds-container_center slds-m-top_medium slds-p-horizontal_x-large th-career-tab-panel__content"
            style={{ minHeight: '120px' }}
          >
            {description}
          </div>
          {renderSkills()}
          {renderStats()}
          { showBottomNotch && (
            <div className="slds-is-relative slds-show_medium slds-m-bottom_large">
              <div className={`th-notch--bottom ${roleColor}__notch`} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CareerPathCard.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  roleColor: PropTypes.string,
  description: PropTypes.string,
  showBottomNotch: PropTypes.bool,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      caption: PropTypes.string
    })
  ),
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string,
      caption: PropTypes.string,
      subCaption: PropTypes.string,
      imgSrc: PropTypes.string
    })
  )
};

export default CareerPathCard;
