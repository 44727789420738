import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel, resetIdCounter } from 'react-tabs';
import analytics from 'lib/analytics';
import { tileType } from './shared/sharedPropTypes';
import SkillTabPanel from './SkillTabPanel';
import FakeRadio from './FakeRadio';

resetIdCounter();

class SkillTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tabIndex: 0 };
  }

  skillTracker = analytics();

  trackSkillSelected = (tabProps) => {
    const payload = {
      pageLocation: 'skill_tabs',
      name: tabProps.englishLabel,
      category: 'home',
      componentType: 'card'
    };

    this.skillTracker.trackEvent('skill_selected', payload);
  };

  selectSkill = (tabIndex) => {
    const lastIndex = this.props.tabs.length - 1;

    if (tabIndex !== lastIndex) {
      this.setState({ tabIndex });
    }
  };

  handleClick(tabProps, tabIndex) {
    this.selectSkill(tabIndex);
    this.trackSkillSelected(tabProps);
  }

  renderTabs() {
    const { tabs } = this.props;
    return (
      <TabList>
        {tabs.map((tabProps, i) => (
          <Tab
            key={`skillTab_${i}`}
            style={{ display: 'inline-block' }}
            className="th-skill-tab"
            selectedClassName="th-skill-tab--selected"
            onClick={() => this.handleClick(tabProps, i)}
          >
            <FakeRadio {...tabProps} />
          </Tab>
        ))}
      </TabList>
    );
  }

  renderTabPanels() {
    const { tabs, tabPanels } = this.props;
    return tabPanels.map((panelProps, i) => (
      <TabPanel key={`skillTabPanel_${i}`}>
        <SkillTabPanel {...panelProps} skillName={tabs[i].englishLabel} />
      </TabPanel>
    ));
  }

  render() {
    return (
      <Tabs
        selectedIndex={this.state.tabIndex}
        onSelect={(tabIndex) => this.selectSkill(tabIndex)}
      >
        {this.renderTabs()}
        <div className="th-skill-tab-panel">{this.renderTabPanels()}</div>
      </Tabs>
    );
  }
}

SkillTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  ),
  tabPanels: PropTypes.arrayOf(
    PropTypes.shape({
      tiles: PropTypes.arrayOf(tileType)
    })
  )
};

export default SkillTabs;
