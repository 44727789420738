// Loads i18n from gem
import I18n from '../../../vendor/assets/javascripts/i18n';
import expose from './expose';

// I18n needs to be exposed so translations can be appended to it.
// if window.locale is undefined (like during testing) fallback to en
if (typeof window !== 'undefined') {
  I18n.locale = Object.is(window.locale, undefined) ? 'en' : window.locale;
}

export { I18n };

expose({ I18n });

// Exports common helper methods
export const t = I18n.t.bind(I18n);

export function buildScopeTranslate(scope) {
  return (key, ...args) => t([scope, key], ...args);
}
