import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ContentTileCollection } from '@devforce/tds-react';
import analytics from 'lib/analytics';
import { tileType, circularImageType } from './shared/sharedPropTypes';
import trackContentEvent from './shared/contentTracker';
import renderButtons from './shared/renderButtons';
import IconContext from '../utils/IconContext';
import CircularImage from './CircularImage';
import CareerPathCard from './CareerPathCard';

class CareerTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      leftPlant: 'left 30px top 120px',
      rightPlant: 'right 85px top 100px'
    };
  }

  componentDidMount() {
    this.section = document.getElementById('careers');
    if (this.section) {
      this.section.addEventListener('mousemove', (e) => {
        this.moveBackground(e);
      });
    }
  }

  componentWillUnmount() {
    if (this.section) {
      this.section.removeEventListener('mousemove');
    }
  }

  moveBackground({ pageX, pageY }) {
    if (window.innerWidth <= 950) {
      return;
    }
    const relX = pageX - this.section.offsetLeft;
    const relY = pageY - this.section.offsetTop;
    const leftX = ((relX - (window.innerWidth / 2)) / window.innerWidth) * 30;
    const rightX = ((relX - (window.innerWidth / 2)) / window.innerWidth) * 40;
    const y = ((relY - (window.innerHeight / 2)) / window.innerHeight) * -50;

    this.setState({
      leftPlant: `left ${30 + leftX}px top ${120 - y}px`,
      rightPlant: `right ${85 - rightX}px top ${100 - y}px`
    });
  }

  buttonTracker = analytics();

  selectTab = (tabIndex) => {
    const lastIndex = this.props.tabs.length - 1;

    if (tabIndex !== lastIndex) {
      this.setState({ tabIndex });
    }
  };

  trackButtonClick = (tabIndex) => {
    const lastIndex = this.props.tabs.length - 1;
    if (tabIndex !== lastIndex) {
      return null;
    }

    const payload = {
      name: 'other_roles',
      category: 'home',
      pageLocation: 'career_tabs',
      componentType: 'button'
    };
    this.buttonTracker.trackEvent('button_clicked', payload);
    return null;
  };

  renderTabs() {
    const { tabs } = this.props;
    return (
      <TabList forceRenderTabPanel>
        {tabs.map((tabProps, i) => (
          <Tab
            key={`careerTab_${i}`}
            className="th-career-tab"
            style={{ display: 'inline-block' }}
            onMouseEnter={() => this.selectTab(i)}
            onClick={() => this.trackButtonClick(i)}
            selectedClassName="th-career-tab--selected"
          >
            <CircularImage
              isSelected={i === this.state.tabIndex}
              noHover={i === this.props.tabs.length - 1}
              {...tabProps}
            />
          </Tab>
        ))}
      </TabList>
    );
  }

  renderContentTiles(panelProps) {
    if (!panelProps.tiles) {
      return null;
    }

    const trackerOpts = {
      category: 'home',
      pageLocation: 'career_tabs',
      careerPath: panelProps.title
    };
    const trackEvent = (action, payload) =>
      trackContentEvent(action, payload, trackerOpts);
    const tilesWithExtraProps = panelProps.tiles.map((tile) => {
      const buttons = renderButtons(tile);
      return { ...tile, ...{ trackEvent, buttons } };
    });

    return (
      <IconContext>
        <div className="slds-p-horizontal_medium">
          <ContentTileCollection
            tiles={tilesWithExtraProps}
            defaultSizeClass="slds-size_1-of-3"
          />
        </div>
      </IconContext>
    );
  }

  renderTabPanels() {
    const { tabPanels, cta } = this.props;
    return tabPanels.map((panelProps, i) => (
      <TabPanel
        key={`careerTabPanel_${i}`}
        selectedClassName="th-career-tab-panel--selected"
      >
        <div
          className="th-career-path-tab-panel"
          style={{
            backgroundPosition: `${this.state.leftPlant}, ${this.state.rightPlant}`
          }}
        >
          <CareerPathCard {...panelProps} />
        </div>
        <h2 className="tds-text-heading_neutraface-large slds-p-top_large slds-p-bottom_x-small slds-text-align_center">
          {cta}
        </h2>
        {this.renderContentTiles(panelProps)}
      </TabPanel>
    ));
  }

  render() {
    return (
      <Tabs
        selectedIndex={this.state.tabIndex}
        onSelect={(tabIndex) => this.selectTab(tabIndex)}
      >
        {this.renderTabs()}
        {this.renderTabPanels()}
      </Tabs>
    );
  }
}

CareerTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape(circularImageType)),
  tabPanels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      roleColor: PropTypes.string,
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          imgSrc: PropTypes.string,
          caption: PropTypes.string
        })
      ).isRequired,
      stats: PropTypes.arrayOf(
        PropTypes.shape({
          number: PropTypes.string,
          caption: PropTypes.string,
          subCaption: PropTypes.string,
          imgSrc: PropTypes.string
        })
      ).isRequired,
      tiles: PropTypes.arrayOf(tileType)
    })
  ),
  cta: PropTypes.string
};

export default CareerTabs;
