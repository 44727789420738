import React from 'react';
import PropTypes from 'prop-types';
import SignUpModalBtn from 'components/auth/buttons/SignUpModalBtn';
import GtmTracker, { transformPayload } from '../../lib/tracking/trackGtm';

const gtmTracker = new GtmTracker();
const afterClick = (englishLabel, location) => {
  const eventAction =
    location === 'Footer' ? 'Footer CTA Clicked' : 'Hero CTA Clicked';
  const payload = {
    eventLabel: englishLabel,
    eventCategory: 'home',
    eventAction,
  };
  gtmTracker.track(transformPayload(payload));
};

export default function RedesignedHeroCTA({
  label,
  englishLabel,
  location,
  btnSize = 'x-large'
}) {
  return (
    <div className="slds-m-top_medium">
      <SignUpModalBtn
        className={`slds-button slds-button--important tds-button_${btnSize} tds-button_important`}
        style={{ fontWeight: 'bold' }}
        afterClick={() => afterClick(englishLabel, location)}
      >
        {label}
      </SignUpModalBtn>
    </div>
  );
}

RedesignedHeroCTA.propTypes = {
  label: PropTypes.string,
  englishLabel: PropTypes.string,
  location: PropTypes.string,
  btnSize: PropTypes.string
};
