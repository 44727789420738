import React from 'react';
import PropTypes from 'prop-types';
import { ContentTileCollection } from '@devforce/tds-react';
import { tileType } from './shared/sharedPropTypes';
import trackContentEvent from './shared/contentTracker';
import renderButtons from './shared/renderButtons';
import IconContext from '../utils/IconContext';

export default function SkillTabPanel({tiles, skillName }) {
  const renderContentTiles = () => {
    if (!tiles) {
      return null;
    }

    const trackerOpts = {category: 'Home', pageLocation: 'Skill Tabs', skillName};
    const trackEvent = (action, content) => trackContentEvent(action, content, trackerOpts);
    const tilesWithExtraProps = tiles.map((tile) => {
      const buttons = renderButtons(tile);
      return { ...tile, ...{ trackEvent, buttons } };
    });

    return (
      <IconContext>
        <div className="slds-p-horizontal_medium">
          <ContentTileCollection tiles={tilesWithExtraProps} defaultSizeClass="slds-size_1-of-3" />
        </div>
      </IconContext>
    );
  };

  return renderContentTiles();
}

SkillTabPanel.propTypes = {
  skillName: PropTypes.string,
  tiles: PropTypes.arrayOf(tileType),
};
