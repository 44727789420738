import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

const onClick = ({ afterClick }) => {
  document.dispatchEvent(new CustomEvent('trailhead_signup'));
  if (typeof afterClick === 'function') afterClick();
};

export const SignUpModalBtn = ({
  children,
  content,
  afterClick,
  className = '',
  style,
  dataId,
  keyboardFocusable = true,
}) => (
  <button
    className={className}
    style={style}
    data-id={dataId || null}
    data-test="header-signup"
    onClick={() => onClick({ afterClick })}
    tabIndex={keyboardFocusable ? '0' : '-1'}
  >
    {children || content || t('layout.header.sign_up')}
  </button>
);

SignUpModalBtn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  afterClick: PropTypes.func,
  style: PropTypes.object,
  keyboardFocusable: PropTypes.bool,
  dataId: PropTypes.string,
};

export default SignUpModalBtn;
