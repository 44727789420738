import railsFetch from 'lib/railsFetch';

const FAVORITES_PATH = '/users/favorites';

function updateFavorite({ type, apiName, favorited }) {
  const method = favorited ? 'POST' : 'DELETE';
  return railsFetch({
    url: FAVORITES_PATH,
    method,
    data: {
      favorite: {
        favoritable_api_name: apiName,
        favoritable_type: type,
      },
    },
  });
}

export default { updateFavorite };
