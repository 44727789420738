import React from 'react';
import PropTypes from 'prop-types';

export default function FakeRadio({ label, href }) {
  const renderAnchor = () => (
    <a href={href}>
      <div className="th-fake-radio__inner slds-p-around_x-small slds-m-top_small slds-m-horizontal_xx-small slds-text-body_small">
        <span className="th-text--bold slds-p-horizontal_small tds-text-size_3">{label}</span>
      </div>
    </a>
  );

  const renderDiv = () => (
    <div className="th-fake-radio__inner slds-p-around_x-small slds-m-top_small slds-m-horizontal_xx-small slds-text-body_small">
      <div className="th-fake-radio__circle slds-m-right_x-small" />
      <span className="th-text--bold slds-p-right_small tds-text-size_3">{label}</span>
    </div>
  );

  return <div className="th-fake-radio">{href ? renderAnchor() : renderDiv()}</div>;
}

FakeRadio.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string
};
