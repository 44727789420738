import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel, resetIdCounter } from 'react-tabs';
import { tileType, circularImageType } from './shared/sharedPropTypes';
import CircularImage from './CircularImage';
import CertificationCard from './CertificationCard';

resetIdCounter();

class CertificationTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tabIndex: 0 };
  }

  selectTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  renderButton = () => <button>{this.props.buttonText}</button>;

  renderTabs() {
    const { tabs, roleColor } = this.props;
    return (
      <TabList forceRenderTabPanel>
        {tabs.map((tabProps, i) => (
          <Tab
            className="th-certification-tab"
            selectedClassName="th-certification-tab--selected"
            key={`certificationTab_${i}`}
            style={{ display: 'inline-block' }}
            onMouseEnter={() => this.selectTab(i)}
          >
            <CircularImage
              roleColor={roleColor}
              isSelected={i === this.state.tabIndex}
              {...tabProps}
            />
          </Tab>
        ))}
      </TabList>
    );
  }

  renderTabPanels() {
    const { tabs, tabPanels } = this.props;
    return tabPanels.map((panelProps, i) => (
      <TabPanel key={`careerTabPanel_${i}`} selectedClassName="th-certification-tab-panel--selected">
        <CertificationCard {...panelProps} englishLabel={tabs[i].englishLabel} />
      </TabPanel>
    ));
  }

  render() {
    return (
      <Tabs selectedIndex={this.state.tabIndex} onSelect={(tabIndex) => this.selectTab(tabIndex)}>
        {this.renderTabs()}
        {this.renderTabPanels()}
      </Tabs>
    );
  }
}

CertificationTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape(circularImageType)),
  tabPanels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      logoSrc: PropTypes.string,
      tile: tileType
    })
  )
};

export default CertificationTabs;
