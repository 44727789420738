import React from 'react';
import PropTypes from 'prop-types';
import IconContext from 'components/utils/IconContext';
import { ButtonIcon } from '@devforce/tds-react';

const loginRedirect = () => {
  document.dispatchEvent(new CustomEvent('trailhead_login'));
};

const UnauthenticatedButton = (props) => {
  const {
    name,
    labels: { addToTrailmix }
  } = props;
  return (
    <IconContext>
      <ButtonIcon
        assistiveText={{ icon: addToTrailmix }}
        title={addToTrailmix}
        name={name}
        onClick={loginRedirect}
        className="slds-button_neutral tds-color_meteorite th-accessible-button_icon"
      />
    </IconContext>
  );
};

UnauthenticatedButton.propTypes = {
  labels: PropTypes.object,
  name: PropTypes.string.isRequired
};

export default UnauthenticatedButton;
