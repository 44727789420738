import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SLDSMenuDropdown, DropdownTrigger } from '@salesforce/design-system-react';
import { Button } from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';

export default class MoreTagsDropdown extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    dropdownId: PropTypes.string,
    displayOnly: PropTypes.bool,
  }
  static defaultProps = {
    displayOnly: false,
  }

  onSelect = ({value}) => {
    const {displayOnly} = this.props;
    if (displayOnly) return;

    const filterable = $('.tiles-filterable').length === 1;

    if (filterable) {
      const onSelectEvent = document.createEvent('Event');
      onSelectEvent.initEvent('setTileFilter', true, true);
      onSelectEvent.value = `${value.type}-${value.filter}`;
      document.dispatchEvent(onSelectEvent);
    } else {
      global.location.href = `/${value.genre}#${value.type}=${value.type}-${value.filter}`;
    }
  }

  getOptions() {
    const {items} = this.props;

    return items.map((item) => ({
      label: item.title,
      value: item
    }));
  }

  // Note: ButtonIcon should be used instead of Button
  // however there is an issue with DropdownTrigger
  // that makes it not work
  render() {
    return (
      <IconContext>
        <SLDSMenuDropdown
          menuStyle={{'minWidth': '200px'}}
          onSelect={this.onSelect}
          options={this.getOptions()}
          align="left"
          id={this.props.dropdownId}
        >
          <DropdownTrigger>
            <Button
              className="tds-button_icon th-accessible-button_icon"
              assistiveText={{ icon: 'Tags' }}
              title={this.props.title}
              iconName="quotes"
              iconCategory="standard"
              variant="icon"
              iconVariant="border-filled"
              iconClassName="tds-button__icon tds-button__icon_custom"
            />
          </DropdownTrigger>
        </SLDSMenuDropdown>
      </IconContext>
    );
  }
}
