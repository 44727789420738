import React from 'react';
import PropTypes from 'prop-types';
import { ContentBrick } from '@devforce/tds-react';
import trackContentEvent from './shared/contentTracker';

const CertificationCard = ({
  title,
  tile,
  description,
  logoSrc,
  cta,
  englishLabel
}) => {
  const renderTile = () => {
    if (!tile) {
      return null;
    }

    const trackerOpts = {
      category: 'home',
      pageLocation: 'certification_tabs',
      certificationName: englishLabel
    };
    const trackEvent = (action, payload) =>
      trackContentEvent(action, payload, trackerOpts);

    return (
      <div className="slds-p-around_large">
        <ContentBrick
          {...tile}
          style={{ boxShadow: 'black 1px 1px' }}
          trackEvent={trackEvent}
        />
      </div>
    );
  };

  return (
    <div
      className="th-certification-card slds-container_center slds-p-around_large tds-bg_white"
      style={{ borderRadius: '25px' }}
    >
      <div className="th-certification-card__outer-card slds-p-around_small">
        <div className="th-certification-card__inner-card">
          <div className="slds-text-align_center slds-p-top_xx-large">
            <img src={logoSrc} alt="" style={{ height: '40px' }} />
            <div className="th-certification-card__content">
              <h2 className="tds-text-heading_trailhead-large slds-p-top_medium">
                {title}
              </h2>
              <div
                className="slds-container_center slds-m-top_x-small slds-p-horizontal_xx-large th-certification-card"
                style={{ minHeight: '110px' }}
              >
                {description}
              </div>
            </div>
            <div className="th-certification-card__cta tds-text_neutraface tds-text-size_5 tds-color_midnight">
              {cta}
            </div>
          </div>
          {renderTile()}
        </div>
      </div>
    </div>
  );
};

CertificationCard.propTypes = {
  tile: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  englishLabel: PropTypes.string.isRequired
};

export default CertificationCard;
