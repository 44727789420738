export function scrollToElement(yPosition, e) {
  if (e) { e.preventDefault(); }
  window.scrollTo({
    top: yPosition,
    behavior: 'smooth'
  });
}

export function getScrollPosition(anchorEl) {
  const position = anchorEl.getBoundingClientRect().top + (window.scrollY - 24);
  return position;
}

export function getScrollPositionById(anchorName) {
  const anchorEl = document.getElementById(anchorName);
  return anchorEl.getBoundingClientRect().top + (window.scrollY - 24);
}
