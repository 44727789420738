import get from 'lodash/get';

const initialSettings = () => {
  let trackingFn;

  if (typeof window !== 'undefined') {
    trackingFn = get(window, 'Trailhead.EventTracking.track', () => {
      console.log('Tracking Fn not set');
    });
  }

  return {
    track: trackingFn,
  };
};

export function createNavTracker(settings, context) {
  function addContext(data) {
    const newContext = { ...data, _parent: context };
    return createNavTracker(settings, newContext);
  }

  function trackEvent(payload) {
    // Custom logic for your analytics backend.
    // This is where you can use the values in the settings
    const { track } = settings;
    const { action, label } = payload;

    // In the future, once we have proper infrastructure
    // so save JSON objects, we can send the whole context
    // object which represents the hierarchy of modules
    // on a page
    track(action, { name: label, category: context.section });
  }

  return { trackEvent, addContext };
}

export function createTracker(settings) {
  function trackEvent(eventName, payload) {
    const { track } = settings;
    track(eventName, payload);
  }

  return { trackEvent };
}

export function navAnalytics(context) {
  return createNavTracker(initialSettings(), context);
}

export default function analytics() {
  return createTracker(initialSettings());
}
