import React from 'react';
import PropTypes from 'prop-types';
import { IconSettings } from '@salesforce/design-system-react';
import { IconSocialSettings } from '@devforce/tds-react';

import socialSprite from '@devforce/tds-styles/dist/assets/icons/social-sprite/svg/symbols.svg';
import socialRoundSprite from '@devforce/tds-styles/dist/assets/icons/social-round-sprite/svg/symbols.svg';
import standardSprite from '@devforce/tds-styles/dist/assets/icons/standard-sprite/svg/symbols.svg';
import utilitySprite from '@devforce/tds-styles/dist/assets/icons/utility-sprite/svg/symbols.svg';
import customSprite from '@devforce/tds-styles/dist/assets/icons/custom-sprite/svg/symbols.svg';
import actionSprite from '@devforce/tds-styles/dist/assets/icons/action-sprite/svg/symbols.svg';
import doctypeSprite from '@devforce/tds-styles/dist/assets/icons/doctype-sprite/svg/symbols.svg';

export default function IconContext({children}) {
  return (
    <IconSocialSettings
      socialSprite={socialSprite}
      socialRoundSprite={socialRoundSprite}>
      <IconSettings
        standardSprite={standardSprite}
        utilitySprite={utilitySprite}
        actionSprite={actionSprite}
        doctypeSprite={doctypeSprite}
        customSprite={customSprite}>
        {children}
      </IconSettings>
    </IconSocialSettings>
  );
}

IconContext.propTypes = {
  children: PropTypes.any
};

IconContext.defaultProps = {
  children: undefined
};
