import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { getScrollPositionById, scrollToElement } from 'lib/scrollTo';
import SignUpModalBtn from '../auth/buttons/SignUpModalBtn';
import GtmTracker, { transformPayload } from '../../lib/tracking/trackGtm';

class StickyNav extends React.Component {
  constructor(props) {
    super(props);
    this.toggleVisibility = throttle(this.toggleVisibility.bind(this), 300);
    this.state = { hidden: true, active: 0 };
    this.showScrollY = 560;
    this.gtmTracker = new GtmTracker();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.toggleVisibility);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleVisibility);
  }

  setActiveNav() {
    let newState = {};
    let active = 0;

    if (this.state.hidden) {
      newState = { hidden: false };
    }
    this.props.intentNav.forEach((nav, i) => {
      if (window.pageYOffset > getScrollPositionById(nav.anchor) - 50) {
        active = i;
      }
    });

    this.setState({ ...newState, ...{ active } });
  }

  toggleVisibility = () => {
    if (window.pageYOffset > this.showScrollY) {
      this.setActiveNav();
    } else if (window.pageYOffset < this.showScrollY && !this.state.hidden) {
      this.setState({ hidden: true });
    }
  };

  trackIntentSelected = (nav) => {
    const payload = {
      eventAction: 'intent_selected',
      eventLabel: nav.anchor,
      eventCategory: 'home',
    };
    this.gtmTracker.track(transformPayload(payload));
  };

  handleClick = (nav, e) => {
    this.trackIntentSelected(nav);
    scrollToElement(getScrollPositionById(nav.anchor), e);
  };

  scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  afterClick = () => {
    const payload = {
      eventCategory: 'header',
      eventLame: 'sign_up',
      eventAction: 'sign_up_clicked'
    };
    this.gtmTracker.track(transformPayload(payload));
  };

  renderIntentNav = (nav, i) => {
    const activeClass =
      this.state.active === i ? 'th-sticky-nav__nav-item--active' : null;

    return (
      <div
        key={nav.title}
        className="tds-bg_white slds-text-align_center slds-col"
      >
        <a
          href={`#${nav.anchor}`}
          className={`tds-color_midnight th-sticky-nav__nav-item ${activeClass}`}
          onClick={(e) => this.handleClick(nav, e)}
          tabIndex="-1"
        >
          <img
            src={nav.iconUrl}
            className="slds-m-vertical_small slds-p-right_small"
            style={{ height: '35px' }}
            alt=""
          />
          <span>{nav.title}</span>
        </a>
      </div>
    );
  };

  render() {
    const { intentNav, logoUrl, logoAlt } = this.props;
    const { hidden } = this.state;

    const topStyle = hidden ? { top: -300 } : { top: 0 };
    const maxWidthStyle = intentNav.length > 3 ? { maxWidth: '40rem' } : { maxWidth: '35rem' };

    return (
      <div className="th-sticky-nav tds-bg_white" style={topStyle}>
        <div className="slds-container_x-large slds-container_center">
          <a href="#" onClick={this.scrollToTop} tabIndex="-1">
            <img
              className="slds-float_left slds-m-vertical_x-small slds-m-left_x-large"
              src={logoUrl}
              alt={logoAlt}
              style={{ height: '42px', width: '42px' }}
            />
          </a>
          <SignUpModalBtn
            className="slds-button slds-button--brand slds-m-right_small tds-button_brand slds-float_right slds-m-vertical_x-small"
            afterClick={this.afterClick}
            keyboardFocusable={false}
          />
          <div className="slds-container_center" style={maxWidthStyle}>
            <div className="slds-grid">
              {intentNav.map((card, i) => this.renderIntentNav(card, i))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StickyNav.propTypes = {
  intentNav: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      iconUrl: PropTypes.string.isRequired,
      anchor: PropTypes.string.isRequired
    })
  ).isRequired,
  logoUrl: PropTypes.string,
  logoAlt: PropTypes.string
};

export default StickyNav;
