import analytics from 'lib/analytics';

const contentTacker = analytics();
const trackContentEvent = (action, content, opts) => {
  const eventName = `${content.subTitle}_${action}`;
  const payload = { name: content.apiName };
  contentTacker.trackEvent(eventName, { ...payload, ...opts });
};

export default trackContentEvent;
